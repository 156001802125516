<template>
  <TTView>
    <VRow>
      <VCol cols="12">
        <PositionCard
          :companies="companies"
          :position="position"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_POSITION } from '../../components/competency-matrix/positions/common';
import PositionCard from '../../components/competency-matrix/positions/PositionCard.vue';

export default {
  name: 'Position',

  components: {
    PositionCard,
  },

  data() {
    return {
      companies: [],
      position: { ...DEFAULT_POSITION },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        const { positionId: id } = this.$route.params;
        const data = { id };
        const [companiesResponse, positionResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.CompetencyMatrix.PositionsGet(data),
        ]);

        this.companies = companiesResponse.companies || [];
        this.position = positionResponse.position || { ...DEFAULT_POSITION };
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        // no-finally
      }
    },
  },
};
</script>
